import React, { Component } from 'react';

// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './casestudyquackskins.scss';

import imgQuote from "../images/quotation_mark.webp";

// import SimpleModal from '../components/utilities/SimpleModal';

class OneImageFullTwo extends Component {
    render() {
        const divStyles = {
            backgroundImage: 'url('+this.props.imageOne+')',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: this.props.heightTwo,
            backgroundColor: this.props.backgroundColor
        };
        return (
            <div className="container-fluid one-image-wrapper p-0">
                <div className="row align-items-center no-gutters">
                    <div className="col-12 bgImg" style={divStyles}>

                    </div>
                </div>
            </div>
        );
    }
}

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className="case-study-two-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.csTwoHedImaLocalFile.childImageSharp.fluid.src})`}}>
                        <img src={imgQuote} alt=""/>
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <img src={pageContext.acf.client_logo.source_url} className="client-logo" width={pageContext.acf.client_logo.width} height={pageContext.acf.client_logo.height} alt={pageContext.yoast.opengraph_title} />
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="first-paragraph">
                            <p>Quackskins is a South Florida-based company that produces a camouflage lineup designed to disappear in bright green cattails, sawgrass, and bulrush found in warm climates or during early Teal season. This purpose-built camo pattern is specifically designed for waterfowl hunters in the warm southern reaches of the migration – the wetlands of South Florida, coastal marshes of the Gulf, the southern Pacific Flyway, and beyond.</p>
                        </div>
                        <a href="https://www.meritmile.com/uploads/2019/03/quackskins-and-merit-mile-case-study.pdf" className="btn sitebtn" target="_blank" rel="nofollow noopener noreferrer">Read the Full Case Study</a>
                    </div>
                    <div className="col-12 col-md-6">
                        <img src="https://www.meritmile.com/uploads/2019/03/graphic-desktop@2x.jpg" className="first-paragraph-image img-fluid" width="616" height="506" alt="" />
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="">
                            <h3>Challenge</h3>
                            <p>Camouflage patterns offered by the established mega-brands and big-box stores don’t provide appropriate concealment for southern environments – and hunters aren’t aware of options specifically suited for warm climates. Quackskins needed to address branding and awareness challenges by creating the proper platform to promote their outerwear to a highly targeted audience.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container single-image-text-wrapper detail-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <img src="https://www.meritmile.com/uploads/2019/03/case-study-quackskins-ui.jpg" className="img-fluid" width="620" height="460" alt="" />
                    </div>
                    <div className="col-md-6 text">
                        <div class="text">
                            <h3>Solutions</h3>
                            <ol>
                                <li>Design a modern, engaging, and mobile-friendly website to tell the Quackskins story with visual cues and compelling content</li>
                                <li>Give Quackskins a professional and competitive edge</li>
                                <li>Appeal the Quackskins brand to a broader geographic audience</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="">
                            <h3>Results</h3>
                            <p>The new website was launched at the Florida Sportsman Expo in Tampa, Florida. Feedback from fellow hunters has been excellent and online sales are up by 50% since the launch.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid cta">
                <div className="row">
                    <div className="col-12">
                        <h2 className="ctaTitle">Start Creating Business Momentum Today</h2>
                        <a href="#footer" className="btn btn-light">Get In Touch</a>
                    </div>
                </div>
            </div>
            <div className="container blockquote">
                <div className="row">
                    <div className="col-12">
                        <div className="text">
                            <blockquote><p>"Site traffic, sales, and social media chatter have all gone up since we launched. I’m excited to use the new website as a platform for growth"</p></blockquote>
                            <p><cite className="blockquote-footer">Alex Pomareda, Quackskins Founder</cite></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container single-image-text-wrapper detail-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <img src="https://www.meritmile.com/uploads/2019/03/deliverables-icons.png" className="img-fluid" width="505" height="276" alt="" />
                    </div>
                    <div className="col-md-6 text">
                        <div className="text">
                            <h3>Deliverables</h3>
                            <ul>
                                <li>Messaging and positioning</li>
                                <li>Website Design</li>
                                <li>Copywriting</li>
                                <li>Photography</li>
                                <li>Google AdWords campaign</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <OneImageFullTwo width={pageContext.childWordPressAcf1ImageFull.image.media_details.width/2} heightTwo={pageContext.childWordPressAcf1ImageFull.image.media_details.height/2} imageOne={pageContext.childWordPressAcf1ImageFull.image.source_url} backgroundColor={pageContext.childWordPressAcf1ImageFull.image_full_bg_color} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 website-btn text-center">
                        <a href="https://quackskins.com/" className="btn sitebtn" target="_blank" rel="noopener noreferrer">View WebSite</a>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="text">
                            <p>"Quackskins was born out of the need to blend in better"</p>
                        </div>
                    </div>
                </div>
            </div>
            
            
        </div>
    </Layout>
)